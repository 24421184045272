.background-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-content:space-between;
  margin-top: 20px;
  justify-content: space-between;
  width: 100%;
  overflow-y: auto ;
}
.background-selector-part-font {
  width: 100px !important;
}
.background-selector-part {
 // flex-basis: calc(33.3% - 20px);
  height: 100px;
  border-radius: 50%;
  cursor: pointer;
  border: none;

  &--active {
    border: 2.5px solid #ef5a3c;
  }
}
