.btn {
  padding: 4px 16px;
  color: #fff;
  background-color: #3A3A3C;
  border-radius: 12px;
  margin-right: 12px;
  border: 0;
  cursor: pointer;
  text-transform: capitalize;

  font-size: 16px;
  line-height: 22px;
  font-weight: 400;

  &:last-child {
    margin-right: 0;
  }

  &-active {
    background-color: #EF5A3C;
  }
}