.app {
    width: 100vw;
    height: 100vh;
    max-width: 390px;
    min-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    &-avatar {
        min-height: 280px;
    }
    &-loader-full {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 999;
        & div {
            display: flex;
            justify-content: center;
        }
    }
    &-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        & div {
            display: flex;
            justify-content: center;
        }
    }

    &-container {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    &-filter {
        filter: blur(1.5rem);
    }
}