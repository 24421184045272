.part-selector {
    width: 100%;
    min-height: 63px;
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    padding: 16px;

    border-bottom: 1px solid #3A3A3C;

    &::-webkit-scrollbar {
        display: none;
    }
}