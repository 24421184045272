.body-part-selector {
  display: flex;
  justify-content: flex-start;
  align-content: space-between;
  flex-wrap: wrap;
  overflow-y: auto;
  width: 100%;
  padding: 16px;

  &::-webkit-scrollbar {
    display: none;
  }

  > * {
    flex: 1 1 calc(33.33% - 16px);

    @media (max-width: 479px) {
      height: 110px;
    }
  }
}

.body-part-selector > * {
  margin-right: 20px;
  margin-bottom: 20px;

  @media (max-width: 479px) {
    margin-right: 12px;
    margin-bottom: 12px;
  }
}

.body-part-selector > *:nth-child(3n) {
  margin-right: 0;
}
