*,
*:before,
*:after {
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #252528;
  overflow: hidden;
  height: 100vh;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Nunito';
  src: local('Nunito'), url('./static/fonts/Nunito-Regular.ttf') format('truetype')
}