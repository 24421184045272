.color-circle {
    min-width: 45px;
    min-height: 45px;
    border-radius: 50%;
    border: 0;
    position: relative;
    margin-right: 15px;
    cursor: pointer;

    &::after {
        content: '';
        position: absolute;
    }

    &-active::after {
        content: '';
        position: absolute;
        top: -7px;
        left: -7px;
        right: -7px;
        bottom: -7px;
        border: #EF5A3C 2.5px solid;
        border-radius: 50%;
    }

    &:last-child {
        margin-right: 0;
    }
}