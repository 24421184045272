.body-part {
  display: flex;
  width: 33.3%;
  max-width: 110px;
  height: 100px;
  margin-bottom: 20px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;

  &:disabled {
    cursor: initial;
  }

  &-avatar {
    width: 100%;
    height: 100%;
  }

  &-active {
    border: 2.5px solid #ef5a3c;
    border-radius: 7px;
  }

  &-unlocked {
    display: none;
  }

  // LEGACY

  &-locked {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: rgba(37, 37, 40, 0.5);
    position: absolute;

    & img {
      width: 26px;
      height: 26px;
    }

    &-cost {
      display: flex;
      align-items: center;
      color: #fff;

      & span {
        margin-right: 4px;
        font-size: 16px;
        line-height: 19px;
        font-weight: bold;
      }
    }
  }
}
