.color-selector {
  width: 100%;
  min-height: 77px;
  max-height: 77px;
  display: flex;
  overflow-x: scroll;
  padding: 16px;

  &::-webkit-scrollbar {
    display: none;
  }
}