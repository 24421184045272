.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 24px 16px;

    &-title {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        color: #fff;
    }

    &-btn-rnd,
    &-btn-done {
        background: none;
        border: none;
        display: flex;
        padding: 0;
        cursor: pointer;
    }

    &-btn-done {
        color: #FEA255;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
    }
}